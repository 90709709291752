<script setup lang="ts">
// TODO: hor- and ver- views
// TODO: make wrapper and remove styles from this

import { computed, ref } from 'vue';

import ScrollPanel from '@/components/Common/ScrollPan/ScrollPanel.vue';

const FADE_ENABLE_DELAY_IN_PX = 20;

const panel = ref<InstanceType<typeof ScrollPanel> | null>(null);

const props = withDefaults(defineProps<{
    initScroll?: number;
    withFade?: boolean;
    orientation?: 'horizontal' | 'vertical';
}>(), {
    initScroll: 0,
    withFade: true,
    orientation: 'vertical',
});

const emit = defineEmits<{
    scroll: [UIEvent];
    scrollUpdate: [{ scrollTop: number; scrollHeight: number; clientHeight: number; scrollBottom: number }];
}>();

const ignoreScrollEvent = ref(false);
const scrollModel = ref(props.initScroll);
const scrollAbsoluteValues = ref({
    scrollTop: 0,
    scrollBottom: 0,
});

const barClass = computed(() => {
    if (scrollAbsoluteValues.value.scrollTop >= 0 && scrollAbsoluteValues.value.scrollTop <= FADE_ENABLE_DELAY_IN_PX) {
        return 'bar-on-top';
    }

    if (scrollAbsoluteValues.value.scrollBottom <= FADE_ENABLE_DELAY_IN_PX && scrollAbsoluteValues.value.scrollBottom >= 0) {
        return 'bar-on-bottom';
    }

    return 'bar-on-middle';
});

const setScroll = (value: number) => {
    scrollModel.value = clamp(0, 1, value);
    panel.value?.updateScroll();
};

defineExpose({
    setScroll,
    updateScroll: () => panel.value?.updateScroll(),
    scrollModel,
});

const isScrollBarShowed = ref(false);

const onAbsoluteValuesUpdate = (event: {
    scrollTop: number;
    scrollHeight: number;
    clientHeight: number;
    scrollBottom: number;
}) => {
    scrollAbsoluteValues.value = event;
    emit('scrollUpdate', event);
};

</script>

<template>
    <div
        :class="[
            'scroll-pan',
            barClass && `scroll-pan_${barClass}`,
            {
                'scroll-pan_bar-hided': !isScrollBarShowed,
                'scroll-pan_fade': withFade,
            }
        ]"
    >
        <CommonScrollPanScrollPanel
            ref="panel"
            v-model="scrollModel"
            class="scroll-pan__panel"
            :ignore-scroll-event="ignoreScrollEvent"
            :orientation="orientation"
            @scroll="emit('scroll', $event)"
            @enable="isScrollBarShowed = true"
            @disable="isScrollBarShowed = false"
            @update-absolute-values="onAbsoluteValuesUpdate($event)"
        >
            <slot />
        </CommonScrollPanScrollPanel>

        <Transition name="fade">
            <CommonScrollPanScrollBar
                v-if="isScrollBarShowed"
                v-model="scrollModel"
                :class="`scroll-pan__bar_${orientation}`"
                :orientation="orientation"
            />
        </Transition>
    </div>
</template>

<style lang="scss" scoped>
$scroll-padding: size(15px);
$scroll-pan-background: #1d1d1d;
$scroll-thumb-width: size(3px);
$scroll-thumb-height: size(50px);

$scroll-thumb-horizontal-height: size(3px);
$scroll-thumb-horizontal-width: size(50px);

.scroll-pan {
    position: relative;
    background: $scroll-pan-background;
    padding:
        $scroll-padding
        calc($scroll-padding + $scroll-padding)
        $scroll-padding
        $scroll-padding;
    height: 100%;
    overflow: hidden;

    &__panel {
        position: relative;
        z-index: 1;
        width: 100%;
        max-height: 100%;
    }

    &__bar_vertical {
        position: absolute;
        top: $scroll-padding;
        right: $scroll-padding;
        transform: translateX(50%);
        z-index: 3;
        height: calc(100% - calc($scroll-padding + $scroll-padding));
    }

    &__bar_horizontal {
        position: absolute;
        bottom: 0;
        right: $scroll-padding;
        transform: translateY(50%);
        z-index: 3;
        width: 100%;
    }

    &_fade:before,
    &_fade:after {
        display: block;
        position: absolute;
        left: 0;
        opacity: 0;
        z-index: 2;
        transition: opacity $base-transition;
        width: 100%;
        height: size(90px);
        pointer-events: none;
        content: "";
    }

    &_fade:before {
        bottom: 0;
        background: linear-gradient(
            to top,
            $scroll-pan-background 10%,
            transparent 100%
        );
    }

    &_fade:after {
        top: 0;
        background: linear-gradient(
            to bottom,
            $scroll-pan-background 10%,
            transparent 100%
        );
    }

    &_bar-on-top {
        &:before {
            opacity: 0.8;
        }
    }

    &_bar-on-middle {
        &:before,
        &:after {
            opacity: 0.8;
        }
    }

    &_bar-on-bottom {
        &:after {
            opacity: 0.8;
        }
    }

    &_bar-hided {
        &:before,
        &:after {
            display: none;
        }
    }
}

:deep(.scrollbar-vertical) {
    display: flex;
    justify-content: center;
    min-width: $scroll-thumb-width;
    min-height: $scroll-thumb-height;

    .scrollbar-vertical__track {
        background: rgba(#D3D3D3, .3);
        width: size(2px);
        height: 100%;
    }

    .scrollbar-vertical__thumb-container {
        position: absolute;
        width: 100%;
        height: calc(100% - $scroll-thumb-height);
    }

    .scrollbar-vertical__thumb {
        width: $scroll-thumb-width;
        height: $scroll-thumb-height;
    }

    .scrollbar-vertical__thumb-button {
        background: rgba(white, .66);
        width: 100%;
        height: 100%;
    }
}

:deep(.scrollbar-horizontal) {
    display: flex;
    justify-content: center;
    min-height: size(8px);
    min-width: $scroll-thumb-horizontal-width;

    .scrollbar-horizontal__track {
        background: rgba(#D3D3D3, .3);
        height: size(2px);
        width: 100%;
    }

    .scrollbar-horizontal__thumb-container {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .scrollbar-horizontal__thumb {
        height: $scroll-thumb-horizontal-height;
        width: $scroll-thumb-horizontal-width;
    }

    .scrollbar-horizontal__thumb-button {
        background: rgba(white, .66);
        width: 100%;
        height: 100%;
    }
}
</style>
