import { onBeforeUnmount, onMounted } from 'vue';

export const useWindowEvent = <Key extends keyof WindowEventMap>(
    key: Key,
    onEvent: (this: Window, ev: WindowEventMap[Key]) => void,
) => {
    onMounted(() => {
        window.addEventListener(key, onEvent);
    });
    onBeforeUnmount(() => {
        window.removeEventListener(key, onEvent);
    });
};
