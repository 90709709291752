<script setup lang="ts">
import { computed, ref, watch } from 'vue';

const props = withDefaults(defineProps<{ modelValue: number; orientation: 'horizontal' | 'vertical' }>(), {
    orientation: 'vertical',
});
const emit = defineEmits(['update:modelValue', 'scrollStart', 'scrollEnd']);

const outerModel = computed({
    get: () => props.modelValue,
    set: value => emit('update:modelValue', clamp(0, 1, value)),
});
const innerModel = ref(props.modelValue);
const mouse = useMouse();
const scrollbar = ref<HTMLDivElement | null>(null);
const thumb = ref<HTMLDivElement | null>(null);

const data = ref<{
    pointer: { x: number; y: number };
    offset: { x: number; y: number };
} | null>(null);

const onPointerDown = (event: PointerEvent) => {
    data.value = {
        pointer: { x: mouse.x.value, y: mouse.y.value },
        offset: { x: event.offsetX, y: event.offsetY },
    };
};
const onPointerUp = () => {
    data.value = null;
};
const updateModel = () => {
    if (data.value !== null && scrollbar.value !== null && thumb.value !== null) {
        const position = { x: mouse.x.value - data.value.offset.x, y: mouse.y.value - data.value.offset.y };
        const scrollbarBounds = scrollbar.value.getBoundingClientRect();
        const thumbBounds = thumb.value.getBoundingClientRect();

        innerModel.value = props.orientation === 'vertical'
            ? clamp(0, 1, (position.y - scrollbarBounds.y) / (scrollbarBounds.height - thumbBounds.height))
            : clamp(0, 1, (position.x - scrollbarBounds.x) / (scrollbarBounds.width - thumbBounds.width));

        outerModel.value = innerModel.value;
    }
};

useWindowEvent('pointerup', onPointerUp);
useResize(updateModel);

watch([mouse.x, mouse.y], updateModel);
watch([outerModel], () => {
    if (data.value !== null) {
        return;
    }

    innerModel.value = outerModel.value;
});

const getTransformString = (value: number) =>
    props.orientation === 'vertical' ? `translateY(${value * 100}%)` : `translateX(${value * 100}%)`;
</script>

<template>
    <div
        ref="scrollbar"
        :class="`scrollbar-${orientation}`"
    >
        <div :class="`scrollbar-${orientation}__track`" />

        <div
            :class="`scrollbar-${orientation}__thumb-container`"
            :style="{ transform: getTransformString(innerModel) }"
        >
            <div
                ref="thumb"
                :class="`scrollbar-${orientation}__thumb`"
            >
                <CommonButtonFrame
                    ref="thumb"
                    :class="`scrollbar-${orientation}__thumb-button`"
                    @pointerdown="onPointerDown"
                    @pointerup="onPointerUp"
                />
            </div>
        </div>
    </div>
</template>
