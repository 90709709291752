<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';

const props = withDefaults(defineProps<{
    modelValue: number;
    ignoreScrollEvent: boolean;
    orientation?: 'horizontal' | 'vertical';
}>(), {
    orientation: 'vertical',
});
const emit = defineEmits(['update:modelValue', 'enable', 'disable', 'update-absolute-values']);

const outerModel = computed({
    get: () => props.modelValue,
    set: value => emit('update:modelValue', clamp(0, 1, value)),
});

const scrollpanel = ref<HTMLDivElement | null>(null);
let isScrollEnabled: boolean | null = null;

const isScrollable = ref(true);

const isVerticalScroll = computed(() => props.orientation === 'vertical');

const onScroll = () => {
    if (scrollpanel.value !== null && !props.ignoreScrollEvent) {
        if (isVerticalScroll.value) {
            outerModel.value = scrollpanel.value.scrollTop / (scrollpanel.value.scrollHeight - scrollpanel.value.clientHeight);
        } else {
            outerModel.value = scrollpanel.value.scrollLeft / (scrollpanel.value.scrollWidth - scrollpanel.value.clientWidth);
        }
    }
};

useResize(onScroll);

const toggleScroll = (isEnabled: boolean) => {
    isScrollable.value = isEnabled;

    if (isEnabled !== isScrollEnabled) {
        isScrollEnabled = isEnabled;
        emit(isEnabled ? 'enable' : 'disable');
    }
};

const updateScroll = async () => {
    await nextTick();

    if (scrollpanel.value !== null) {
        if (isVerticalScroll.value) {
            const isEnabled = scrollpanel.value.scrollHeight > scrollpanel.value.clientHeight;
            const top = (scrollpanel.value.scrollHeight - scrollpanel.value.clientHeight) * outerModel.value;

            toggleScroll(isEnabled);

            isEnabled && scrollpanel.value.scrollTo({ top });
        } else {
            const isEnabled = scrollpanel.value.scrollWidth > scrollpanel.value.clientWidth;
            const left = (scrollpanel.value.scrollWidth - scrollpanel.value.clientWidth) * outerModel.value;

            toggleScroll(isEnabled);

            isEnabled && scrollpanel.value.scrollTo({ left });
        }

        emit('update-absolute-values', {
            scrollTop: scrollpanel.value.scrollTop,
            scrollHeight: scrollpanel.value.scrollHeight,
            clientHeight: scrollpanel.value.clientHeight,
            scrollBottom: scrollpanel.value.scrollHeight - scrollpanel.value.clientHeight - scrollpanel.value.scrollTop,
        });
    }
};

const onWheel = (event: WheelEvent) => {
    if (!isVerticalScroll.value && scrollpanel.value) {
        scrollpanel.value.scrollLeft += event.deltaY < 0 ? -30 : 30;
        event.preventDefault();
    }
};

defineExpose({
    updateScroll,
    isScrollable,
});

watch([outerModel], () => {
    updateScroll();
}, {
    immediate: true,
});

onMounted(() => {
    updateScroll();
});
</script>

<template>
    <div
        ref="scrollpanel"
        :class="[
            'scroll-panel',
            {
                'scroll-panel_vertical': isVerticalScroll,
                'scroll-panel_horizontal': !isVerticalScroll,
            }
        ]"
        @scroll="onScroll"
        @wheel="onWheel"
    >
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.scroll-panel {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    pointer-events: initial;
}

.scroll-panel_horizontal {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.scroll-panel_vertical {
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>
