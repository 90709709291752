import { onBeforeUnmount, onMounted } from 'vue';

export interface Point {
    x: number;
    y: number;
}

export interface Size {
    width: number;
    height: number;
}

export interface Bounds extends Point, Size {}


export function getWidth() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}

export function getHeight() {
    return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
}

export const useResize = (onResize: (bounds: Bounds) => void, element?: HTMLElement | (() => HTMLElement | undefined)) => {
    if (element === undefined) {
        useWindowEvent('resize', () => onResize({ x: 0, y: 0, width: getWidth(), height: getHeight() }));
    } else {
        const getElement = typeof element === 'function' ? element : () => element;

        const resizeObserver = new ResizeObserver(entries => {
            if (!entries.length) {
                return;
            }

            const element = getElement();
            if (element === undefined) {
                return;
            }

            const bounds = element.getBoundingClientRect();

            onResize({
                x: bounds.left,
                y: bounds.top,
                width: bounds.width,
                height: bounds.height,
            });
        });

        onMounted(() => {
            const element = getElement();
            if (element === undefined) {
                return;
            }

            resizeObserver.observe(element);
        });
        onBeforeUnmount(() => {
            resizeObserver.disconnect();
        });
    }
};
