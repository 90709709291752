<script setup lang="ts">
enum MOUSE_BUTTONS {
    LEFT = 1,
    RIGHT = 2,
    MIDDLE = 4,
    BACK = 8,
    FORWARD = 16,

    ALL = 31,
}

function buttonContaints(a: number, b: number) {
    return (a & b) === b;
}

const emit = defineEmits<{
    (event: 'click', payload: MouseEvent): void;

    (event: 'pointerdown', payload: PointerEvent): void;
    (event: 'pointerup', payload: PointerEvent): void;
    (event: 'pointerout', payload: PointerEvent): void;
    (event: 'pointerover', payload: PointerEvent): void;
    (event: 'pointerenter', payload: PointerEvent): void;
}>();

const props = withDefaults(
    defineProps<{ isEnabled?: boolean; buttons?: number }>(),
    { isEnabled: true, buttons: MOUSE_BUTTONS.LEFT },
);
</script>

<template>
    <div
        :style="{ pointerEvents: 'initial' }"
        @click="payload => isEnabled && emit('click', payload)"

        @pointerdown="payload => isEnabled && buttonContaints(props.buttons, payload.buttons) && emit('pointerdown', payload)"
        @pointerover="payload => isEnabled && emit('pointerover', payload)"
        @pointerup="payload => isEnabled && emit('pointerup', payload)"
        @pointerout="payload => isEnabled && emit('pointerout', payload)"
        @pointerenter="payload => isEnabled && emit('pointerenter', payload)"
    >
        <slot />
    </div>
</template>
